<template>
  <v-alert icon="mdi-bullhorn" :color="color" dark>
    <div class="text-h6" v-text="announcement.title"></div>
    {{ announcement.text }}
  </v-alert>
</template>

<script>
import gql from "graphql-tag";

export const ANNOUNCEMENT_DATA_FRAGMENT = gql`
  fragment announcementData on Announcement {
    title
    text
    announcementable {
      __typename
      ... on Announcementable {
        id
      }
    }
  }
`;

export default {
  props: ["announcement"],

  computed: {
    color() {
      switch (this.announcement.announcementable.__typename) {
        case "Client":
          return "warning";
        case "Area":
          return "primary";
        case "Residence":
          return "info";
        default:
          return "";
      }
    },
  },
};
</script>
