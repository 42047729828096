import { url } from "vuelidate/lib/validators";

export default function getPayableFields() {
  return [
    {
      name: "has_payments",
      type: "checkbox",
      label: this.$t("payable.has_payments"),
    },
    {
      name: "payment_link",
      label: this.$t("payable.payment_link"),
      isVisible: ({ has_payments }) => has_payments,
      validation: {
        url,
      },
    },
  ];
}
