import _range from "lodash/range";
import moment from "moment";

export const MODELS = {
  USERMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    username: {},
    password: {
      type: "password",
    },
    name: {},
    surname: {},
    email: {},
    phone: {},
    type: {
      type: "autocomplete",
      options: ["guest", "student", "meals_admin", "course_admin", "residence_admin", "area_admin", "client_admin", "super_admin"],
    },
    residence_id: {
      is_parent_id: true,
      query_path: "residence.area.residences",
    },
  },

  COURSEMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    area_id: {
      hidden: true,
      is_parent_id: true,
    },
    year_id: {
      hidden: true,
      default: 1,
      is_parent_id: true,
    },
    name: {},
    organizer: {},
    cost: { default: 0, type: "number", step: 0.01 },
    manager_id: {
      nullable: true,
      query_path: "area.managers",
    },
    tag_id: {
      query_path: "year.tags",
      nullable: true,
    },
  },

  DEGREEMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    type: {
      type: "autocomplete",
      options: ["degree", "certification", "other"],
    },
    name: {},
    date: {
      type: "date",
      default: moment().format("YYYY-MM-DD"),
    },
    grade: { type: "combobox", options: [..._range(66, 111).map((num) => num.toString()), "110L"].reverse(), no_translation: true },
    student_id: {
      hidden: true,
    },
  },

  STUDENTANAGRAPHICMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    gender: {
      type: "autocomplete",
      options: ["M", "F"],
    },
    cf: {},
    birthday: { type: "date" },
    nationality: {},
    address: {},
    city: {},
    province: {},
    cap: {},
    id_card_number: {},
    idCard: { type: "file" },
    privacy: { type: "file" },
  },

  STUDENTACADEMICMODEL: {
    id: {
      default: -1,
      hidden: true,
      is_parent_id: true,
    },

    primary_career_year_id: {
      query_path: "studentYear.careerYears",
      text: "career.studyClass.name",
      nullable: true,
    },

    // structure: {
    //
    // },
    // class: {
    //
    // },
    // class_area: {
    //
    // },
    // type: {
    //
    // },
  },

  STUDENTADMINISTRATIONMODEL: {
    id: {
      default: -1,
      hidden: true,
      is_parent_id: true,
    },
    family_count: { type: "number" },
    room_rate: { type: "number", step: 0.01 },
    services_cost: { type: "number", step: 0.01 },

    college_year: {
      type: "autocomplete",
      options: _range(1, 8),
      no_translation: true,
    },

    type: {
      type: "autocomplete",
      options: ["S", "E", "R", "C", "T", "D", "M"],
    },

    isr: { type: "number", step: 0.01 },
    isp: { type: "number", step: 0.01 },
    ise: { type: "number", step: 0.01 },
    attributions: { type: "number", step: 0.01 },
    s_eq: { type: "number", step: 0.01 },
    inps_reduction: { type: "number", step: 0.01 },
    other_reductions: { type: "number", step: 0.01 },

    residence_id: {
      query_path: "studentYear.residence.area.residences",
    },

    tuition_formula_id: {
      query_path: "studentYear.year.tuitionFormulas",
    },

    residence_start: { type: "date" },
    residence_end: { type: "date" },
    contract_time: { type: "number" },
    residence_abroad_time: { type: "number" },

    notes: {
      type: "textarea",
    },
  },

  STUDENTMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    residence_id: {
      is_parent_id: true,
      query_path: "residence.area.residences",
    },
    year_id: {},
  },

  STUDENTDOCUMENTSMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    contract: {
      type: "file",
    },
    educationAgreement: {
      type: "file",
    },
    iseeDocumentation: {
      type: "file",
    },
    examsCertification: {
      type: "file",
    },
    transcript: {
      type: "file",
    },
  },

  STUDY_CLASSMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    name: {},
    code: {},
    structure: {
      type: "autocomplete",
      options: ["university", "AFAM"],
    },
    study_duration_id: {},
    class_area_id: {},
  },

  EVENTMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    name: {},
    argument: {},
    start_timestamp: {
      type: "datetime",
    },
    end_timestamp: {
      type: "datetime",
    },
    subscription_start: {
      type: "datetime",
    },
    subscription_deadline: {
      type: "datetime",
    },
    max_subscribers: {},
    teacher: {},
    organizer: {},
    link: { type: "link" },
    type: {
      type: "autocomplete",
      options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    },
    qualification: {
      type: "autocomplete",
      options: [0, 1],
    },
    context: {
      type: "autocomplete",
      options: ["N", "I"],
    },
    cost: { default: 0, type: "number", step: 0.01 },
    notes: {
      type: "textarea",
    },
    delegate_id: {
      nullable: true,
      query_path: "course.area.managers",
    },
    room_id: {
      query_path: "course.area.rooms",
    },
    course_id: {
      is_parent_id: true,
      query_path: "course.area.courses",
    },
  },

  ENROLLEDMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    course_id: { is_parent_id: true, hidden: true },
    student_year_id: {
      label: "student",
      text: "full_name",
      query_path: "course.year.studentYears",
      parent_id_variable: "id",
    },
  },

  PRESENTMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    event_id: { is_parent_id: true, hidden: true },
    student_year_id: {
      label: "student",
      text: "student.name",
      query_path: "event.course.year.studentYears",
    },
  },

  MEALMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    residence_id: { is_parent_id: true, hidden: true },
    user_id: {
      label: "user",
      text: "complete_name", //user=> `${user.name} ${user.surname}`,
      query_path: "residence.area.users",
      parent_id_variable: "id",
      nullable: true,
    },
    description: {},
  },

  PRESENCEMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    event_id: { is_parent_id: true, hidden: true },
    student_year_id: {
      label: "student",
      text: "student.name",
      query_path: "event.course.year.studentYears",
    },
  },

  SUBSCRIBERMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    event_id: { is_parent_id: true, hidden: true },
    student_year_id: {
      label: "student",
      text: "student.name",
      query_path: "event.course.year.studentYears",
    },
  },

  FILEMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    name: {
      type: "autocomplete",
      options: ["contract", "education_agreement", "isee", "exam_selfcertification", "formation_booklet", "other"],
    },
    student_id: {},
    file: {
      default: null,
    },
  },

  CAREERMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    study_class_id: {},
    structure_id: {},
    student_id: {
      hidden: true,
    },
  },

  CAREER_YEARMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    student_year_id: {
      text: "year.name",
      query_path: "career.student.studentYears",
      parent_id_variable: "id",
    },
    class_year_id: {
      query_path: "career.studyClass.studyDuration.classYears",
      parent_id_variable: "id",
    },
    career_id: {
      hidden: true,
      is_parent_id: true,
    },
  },

  TAGMODEL: {
    id: {
      default: -1,
      hidden: true,
      is_parent_id: true,
    },
    name: {
      default: "",
    },
    color: {
      default: "",
      type: "color",
    },
    year_id: {
      query_path: "tag.client.years",
      multiple: true,
    },
  },

  PAYMENTMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    student_id: {
      hidden: true,
    },
    date: {
      type: "date",
    },
    amount: {
      type: "number",
      step: 0.01,
    },
    type: {},
    description: {},
  },

  TUITION_RANGEMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    name: {
      default: "",
    },
    isee_limit: {
      type: "number",
      step: 0.01,
    },
    isee_reduction: {
      type: "number",
      step: 0.01,
    },
    residence_year_id: {
      is_parent_id: true,
      hidden: true,
    },
  },

  RESIDENCE_YEARMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    max_tuition: {
      type: "number",
      step: 0.01,
    },
    education_bonus: {
      type: "number",
      step: 0.01,
    },
  },

  GRADE_REFERENCEMODEL: {
    id: {
      default: -1,
      hidden: true,
    },
    grade_reference: {
      default: 30,
      step: 0.1,
      type: "number",
    },
    year_id: {
      default: "",
      query_path: "area.client.years",
      parent_id_variable: "id",
    },
    class_area_id: {
      default: "",
    },
    class_year_id: {
      default: "",
    },
    area_id: {
      default: "",
      query_path: "area.client.areas",
      is_parent_id: true,
    },
  },
};
