<template>
  <v-card rounded="lg">
    <v-card-title style="word-break: break-word">
      <span class="text-wrap text-uppercase text-h6 text-center text-md-left">{{ moment(value).locale($i18n.locale).format("MMMM YYYY") }}</span>
    </v-card-title>
    <v-card-text>
      <v-container class="py-1 d-flex">
        <v-btn icon class="ma-2" @click="$refs.calendar.prev()"> <v-icon>mdi-chevron-left</v-icon><span>Passati</span> </v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="value == currentDay" color="primary" class="ma-2" @click="value = currentDay">OGGI</v-btn>
        <v-spacer></v-spacer>
        <v-btn icon class="ma-2" @click="$refs.calendar.next()"> <span>Prossimi</span><v-icon>mdi-chevron-right</v-icon> </v-btn>
      </v-container>
      <v-progress-linear class="mb-4" indeterminate v-if="loading" />
      <v-calendar
        ref="calendar"
        v-model="value"
        :events="events"
        :event-color="(event) => (event.course.tag ? event.course.tag.color : '#555')"
        :type="type"
        interval-height="32"
        event-end="end_timestamp"
        event-start="start_timestamp"
        :locale="$i18n.locale"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        @mouseenter:event="showEventWindow"
        @click:event="eventClicked"
        first-time="08:00"
        interval-count="15" />
      <v-menu v-model="menuVisibile" :close-on-content-click="false" :activator="selectedElement" offset-y open-on-hover>
        <event-window
          @show-poster="showPoster"
          @editing="editing = $event"
          @mouseleave="hideEvent"
          v-if="menuVisibile"
          :event_id="selectedEvent.id" />
      </v-menu>
    </v-card-text>

    <file-preview v-if="poster" v-model="preview" :src="poster.link" :title="selectedEvent.name" />
  </v-card>
</template>

<script>
export const CALENDAR_EVENT_FRAGMENT = gql`
  fragment calendarEvent on Event {
    id
    name
    start_timestamp
    end_timestamp
    course {
      id
      tag {
        id
        color
      }
    }
  }
`;

import moment from "moment";
import gql from "graphql-tag";
import EventWindow from "./home/EventWindow.vue";
import FilePreview from "./base/FilePreview.vue";

export default {
  components: { EventWindow, FilePreview },
  name: "Calendar",
  data() {
    return {
      selectedEvent: null,
      selectedElement: null,
      menuVisibile: false,
      value: null,
      editing: false,
      moment,
      preview: false,
      poster: null,
    };
  },
  mounted() {
    this.value = this.currentDay;
  },

  methods: {
    showPoster(poster) {
      this.poster = poster;
      this.preview = true;
    },

    showEventWindow({ nativeEvent, event }) {
      if (!this.menuVisibile) {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.menuVisibile = true;
        }, 10);
      }

      nativeEvent.stopPropagation();
    },

    hideEvent() {
      if (!this.editing) this.menuVisibile = false;
    },

    eventClicked(eventInfo) {
      if (this.menuVisibile) {
        this.hideEvent();
      } else {
        this.showEventWindow(eventInfo);
      }
    },
  },
  watch: {
    firstDayVisible(value) {
      this.$emit("input", { from: value, to: this.lastDayVisible });
    },
    lastDayVisible(value) {
      this.$emit("input", { from: this.firstDayVisible, to: value });
    },
  },
  computed: {
    currentDay() {
      return moment().format("YYYY-MM-DD");
    },
    firstDayVisible() {
      if (this.type === "week") return moment(this.value).weekday(0).format("YYYY-MM-DD");

      return moment(this.value).format("YYYY-MM-DD");
    },
    lastDayVisible() {
      if (this.type === "week") return moment(this.value).weekday(6).add(1, "d").format("YYYY-MM-DD");

      return moment(this.value).add(1, "d").format("YYYY-MM-DD");
    },
  },
  props: {
    type: {
      type: String,
      default: "week",
    },
    events: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
