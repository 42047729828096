export const HEADERS = {
  user: [
    { value: "username", text: "username" },
    { value: "surname", text: "surname" },
    { value: "name", text: "name" },
    { value: "email", text: "email" },
    { value: "phone", text: "phone" },
    { value: "type", text: "type", hasTemplate: true },
    // { value: "residence.name", text: "residence" },
  ],
  course: [
    { value: "name", text: "name" },
    { value: "event_count", text: "event_number" },
    { value: "tag.name", text: "tag", hasTemplate: true },
    { value: "manager.name", text: "manager", hasTemplate: true },
  ],
  degree: [
    { value: "name", text: "name" },
    { value: "grade", text: "grade" },
    { value: "date", text: "date" },
    { value: "attachment", text: "attachment", type: "file" },
  ],
  student: [
    { value: "year.name", text: "year" },
    // { value: "residence_start", text: "residence_start" },
    // { value: "residence_end", text: "residence_end" },
    // { value: "tuition", text: "tuition" },
    // { value: "goal_time", text: "goal_time" },
    // { value: "class", text: "class" },
    // { value: "class_year", text: "class_year" },
    { value: "residence.name", text: "residence" },
    // {
    //   value: "expandable",
    //   text: [
    //     { value: "birthday", text: "birthday" },
    //     { value: "gender", text: "gender" },
    //     { value: "residence_abroad", text: "residence_abroad" },
    //     { value: "nationality", text: "nationality" },
    //     { value: "structure", text: "structure" },
    //     { value: "type", text: "type" },
    //   ],
    // },
  ],
  event: [
    { value: "name", text: "name" },
    { value: "start_timestamp", text: "start_timestamp" },
    { value: "end_timestamp", text: "end_timestamp" },
    // { value: "argument", text: "argument" },
    // { value: "teacher", text: "teacher" },
    // { value: "link", text: "link" },
    // { value: "type", text: "type" },
    // { value: "qualification", text: "qualification" },
    // { value: "context", text: "context" },
    // { value: "cost", text: "cost" },
    // { value: "notes", text: "notes" },
    // { value: "creator.name", text: "creator.name" },
  ],
  enrolled: [
    { value: "full_name", text: "name" },
    { value: "enrollment_pivot.created_at", text: "enrolled_at" },
  ],
  presence: [
    { value: "start_timestamp", text: "date", hasTemplate: true },
    { value: "name", text: "name" },
    { value: "course.tag.name", text: "tag", hasTemplate: true },
    { value: "type", text: "type", hasTemplate: true },
    { value: "acknowledged", text: "acknowledged", hasTemplate: true },
    { value: "duration", text: "duration", hasTemplate: true },
  ],
  meal: [
    { value: "user.name", text: "name", hasTemplate: true },
    { value: "user.residence.name", text: "residence" },
    { value: "sign_mode", text: "sign_mode", hasTemplate: true },
    { value: "updated_at", text: "signed_at" },
  ],
  file: [
    { value: "name", text: "name" },
    { value: "created_at", text: "uploaded_at" },
  ],
  career: [
    { value: "studyClass.name", text: "name", hasTemplate: true },
    { value: "structure.name", text: "structure" },
    { value: "badge_number", text: "badge_number" },
    { value: "studyClass.code", text: "code" },
    // { value: "careerYears.length", text: "years" },
    { value: "studyClass.studyDuration.name", text: "duration", hasTemplate: true },
  ],
  career_year: [
    { value: "classYear.name", text: "class_year" },
    { value: "studentYear.year.name", text: "year", hasTemplate: true },
  ],
  grade_reference: [
    { value: "classArea.name", text: "class_area" },
    { value: "class_year", text: "class_year" },
    { value: "grade_reference", text: "grade_reference" },
  ],
  cfu_reference: [
    { value: "classYear.name", text: "class_year" },
    { value: "cfu_reference", text: "cfu_reference" },
  ],
  study_class: [
    { value: "name", text: "name" },
    { value: "code", text: "code" },
  ],
  payment: [
    { value: "amount", text: "amount" },
    { value: "date", text: "date" },
    { value: "type", text: "type", hasTemplate: true },
    { value: "description", text: "description" },
  ],
  course_payment: [
    { value: "studentYear.full_name", text: "student" },
    { value: "amount", text: "amount" },
    { value: "date", text: "date" },
    { value: "description", text: "description" },
  ],
  tag: [
    { value: "name", text: "name" },
    { value: "color", text: "color", hasTemplate: true },
    { value: "max_hours", text: "max_hours" },
  ],
  tuition_range: [
    { value: "name", text: "name" },
    { value: "isee_limit", text: "isee_limit" },
    { value: "isee_reduction", text: "isee_reduction" },
  ],
  room: [{ value: "name", text: "name" }],
  scoreboard: [
    {
      text: "position",
      value: "position",
    },
    {
      text: "name",
      value: "name",
    },
    {
      text: "residence",
      value: "residence",
    },
    {
      text: "time",
      value: "time",
    },
  ],
};
