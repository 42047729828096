<template>
  <v-dialog :value="value" @input="(value) => $emit('input', value)" persistent max-width="500px">
    <v-card>
      <v-card-title class="headline">{{ $t("dialog.sure_to_delete") }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('input', false)">{{ $t("dialog.cancel") }}</v-btn>
        <v-btn color="blue darken-1" text @click="deleteItem">{{ $t("dialog.ok") }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },

  methods: {
    deleteItem() {
      this.$emit("confirm");
      this.$emit("input", false);
    },
  },
};
</script>
