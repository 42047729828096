import gql from "graphql-tag";

export default gql`
  fragment enrollableForm on Enrollable {
    id
    enrollments_enabled
    enrollments_start
    enrollments_deadline
    enrollments_max_number
    enrollments_status
  }
`;
