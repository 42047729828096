<template>
  <v-data-table
    @pagination="$emit('page', $event.page)"
    v-bind="tableProps"
    :no-data-text="$t('no_data')"
    :headers="headers"
    :items="items"
    :search="search"
    :loading="loading"
    locale="it-IT">
    <template v-if="!hideHeader" v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title v-if="!searching" class="text-truncate text-h6 text-uppercase pr-2">
          {{ title.charAt(0).toUpperCase() + title.slice(1) }} <span v-if="showTotal">({{ items.length }})</span>
        </v-toolbar-title>
        <v-spacer v-if="!searching" />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('search')"
          ref="search"
          single-line
          dense
          hide-details
          clearable
          v-if="searching"
          @blur="searchBlur"></v-text-field>
        <v-btn fab depressed x-small v-if="!searching" @click="startSearch" color="primary" dark>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab depressed x-small v-if="!viewOnly" @click="editItem()" color="primary" dark class="ml-1">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-dialog v-model="dialog" max-width="800px" persistent>
        <slot name="form" :duplicating="duplicating" :selected_id="editedItem.id" :close="() => (dialog = false)">
          <div v-if="!viewOnly">
            <create-edit-dialog
              :model="model"
              :editedItem="editedItem"
              :dialog="dialog"
              :baseItem="baseItem"
              @close="() => (dialog = false)"
              @save="save"
              v-if="!duplicating" />
            <create-edit-dialog
              :model="model"
              :editedItem="null"
              :dialog="dialog"
              :baseItem="duplicatingItem"
              @close="() => (dialog = false)"
              @save="save"
              v-else />
          </div>
        </slot>
      </v-dialog>

      <delete-dialog v-model="dialogDelete" v-if="!viewOnly" @confirm="deleteItemConfirm" />

      <!-- <div v-for="field in fieldsWithTemplate" :key="field.value"></div> -->
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon v-if="canEdit" small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon v-if="canEdit && !no_duplicates" small class="mr-2" @click="duplicateItem(item)"> mdi-content-copy </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <slot name="name" v-bind:item="item">{{ item.name }}</slot>
    </template>
    <template v-slot:[`item.username`]="{ item }">
      <slot name="username" v-bind:item="item">{{ item.username }}</slot>
    </template>
    <template v-slot:[`item.year.name`]="{ item }">
      <slot name="year.name" v-bind:item="item">{{ item.year.name }}</slot>
    </template>

    <template v-for="field in fieldsWithTemplate" v-slot:[`item.${field.value}`]="{ item }">
      <slot :name="field.value" v-bind:item="item">{{ _get(item, field.value) }}</slot>
    </template>

    <template v-for="slot in slotsWithTimestamp" v-slot:[`item.${slot}`]="{ item }">
      <slot :name="slot" v-bind:item="item">{{ moment(_get(item, slot)).format("lll") }}</slot>
    </template>

    <template v-slot:[`item.date`]="{ item }">
      {{ moment(item.date).format("L") }}
    </template>

    <template v-slot:[`item.amount`]="{ item }">
      <span>
        {{
          item.amount.toLocaleString($i18n.locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        }}
        €
      </span>
    </template>
  </v-data-table>
</template>

<script>
import { HEADERS } from "@/maia/headers";

import _get from "lodash/get";

import CreateEditDialog from "@/components/CreateEditDialog.vue";
import DeleteDialog from "@/components/DeleteDialog.vue";

import moment from "moment";
import { extractIds } from "@/apollo/helpers";
import Vue from "vue";

export default {
  name: "CrudTable",

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      duplicating: false,
      searching: false,
      editedItem: {},
      page: 1,
      moment,
      search: "",
      slotsWithTimestamp: [
        "start_timestamp",
        "end_timestamp",
        "created_at",
        "updated_at",
        "pivot.created_at",
        "enrollment_pivot.created_at",
        "pivot.updated_at",
        "pivot.signed_at",
        "pivot.subscribed_at",
        "subscribed_at",
        "enrolled_at",
      ],
    };
  },

  props: {
    tableProps: { type: Object, default: () => ({}) },
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Title",
    },
    model: {
      type: String,
      required: true,
    },
    baseItem: {
      type: Object,
      default: () => {},
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    no_duplicates: Boolean,
    createText: {
      type: String,
      default() {
        return this.$t("dialog.add");
      },
    },
    loading: Boolean,
  },

  components: {
    CreateEditDialog,
    DeleteDialog,
  },

  computed: {
    headers() {
      let headers = HEADERS[this.model].map((column) => ({
        ...column,
        text: this.$t("headers." + column.text),
      }));
      //headers[0].width = "35%";
      if (!this.viewOnly)
        headers.push({
          text: this.$t("headers.actions"),
          value: "actions",
          sortable: false,
        });

      return headers;
    },
    fieldsWithTemplate() {
      return HEADERS[this.model].filter((header) => header.hasTemplate);
    },

    duplicatingItem() {
      if (this.editedItem == {}) return null;

      // eslint-disable-next-line no-unused-vars
      const { id, ...duplicatingItem } = this.editedItem;
      return duplicatingItem;
    },
  },

  methods: {
    _get,
    editItem(item = null) {
      if (item != null) {
        this.editedItem = extractIds(item);
      } else {
        this.editedItem = {};
      }
      this.duplicating = false;
      this.dialog = true;
    },

    duplicateItem(item) {
      this.editedItem = extractIds(item);
      this.duplicating = true;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedItem = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$emit("deleteItem", this.editedItem);
    },

    save(input) {
      Object.keys(input).forEach((key) => {
        if (input[key] === this.editedItem[key] && (input[key] ?? "") === "") delete input[key];
      });

      if (input.id === -1) {
        delete input.id;
        this.$emit("createItem", input);
        this.duplicating = false;
      } else {
        this.$emit("updateItem", input);
      }
      this.dialog = false;
    },

    searchBlur() {
      if (!this.search) this.searching = false;
    },

    startSearch() {
      this.searching = true;
      Vue.nextTick(() => this.$refs.search.$refs.input.focus());
    },
  },
};
</script>
