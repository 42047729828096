<template>
  <v-form ref="form" @submit.prevent="save">
    <v-card>
      <v-card-title class="text-h6 text-capitalize font-weight-regular">
        <slot name="title">
          <span v-if="duplicating">{{ $t("duplicate") }}</span>
          <span v-else-if="modelEdit.id">{{ $t("edit") }}</span>
          <span v-else>{{ $t("new") }}</span>
          &nbsp;
          <span class="text-lowercase">{{ title || $tc(`models.${model}`) }}</span>
        </slot>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4 text-center">
        <v-alert
          class="text-left font-weight-bold"
          color="warning"
          dark
          v-for="(message, i) in genericGQLErrors"
          :key="i"
        >
          {{ message }}
        </v-alert>

        <v-row no-gutters v-if="!loading">
          <v-col
            v-for="field in visibleFields"
            :key="field.name"
            cols="12"
            :sm="field.cols || '6'"
            style="max-width: 100%"
            :class="[{ 'order-last': field.last }, 'flex-grow-1', 'flex-shrink-0', 'px-4']"
          >
            <ApolloQuery
              :query="field.query"
              :variables="field.variables && field.variables(modelEdit)"
              :update="field.update"
              :skip="field.skip && field.skip(modelEdit)"
              v-if="field.type == 'query'"
            >
              <template v-slot="{ result: { data, loading } }">
                <v-autocomplete
                  :loading="loading"
                  :items="!loading && data ? _get(data, field.path) : []"
                  :item-text="field.text || 'name'"
                  :label="field.label || $t(`${model}.${field.name}`)"
                  v-model="modelEdit[field.name]"
                  v-bind="field.bind"
                  return-object
                  auto-select-first
                  :disabled="field.skip && field.skip(modelEdit)"
                  :no-data-text="$t('no_results')"
                  :error-messages="validationErrors(field)"
                  @input="touch(field)"
                  @blur="touch(field)"
                />
              </template>
            </ApolloQuery>

            <ApolloQuery
              :query="field.query"
              :variables="field.variables && field.variables()"
              v-else-if="field.type == 'api' && (!field.skip || !field.skip())"
            >
              <template v-slot="{ result: { data, loading } }">
                <v-combobox
                  v-if="field.allow_unknown"
                  :loading="loading"
                  :items="!loading && data ? _get(data, field.path) : []"
                  :label="field.label || $t(`${model}.${field.name}`)"
                  v-model="modelEdit[field.name]"
                  v-bind="field.bind"
                  :no-data-text="$t('no_results')"
                  :error-messages="validationErrors(field)"
                  @input="touch(field)"
                  @blur="touch(field)"
                />
                <v-autocomplete
                  :loading="loading"
                  :items="!loading && data ? _get(data, field.path) : []"
                  item-text="name"
                  item-value="id"
                  :no-data-text="$t('no_results')"
                  :label="field.label || $t(`${model}.${field.name}`)"
                  v-model="modelEdit[field.name]"
                  v-bind="field.bind"
                  auto-select-first
                  v-else
                  :error-messages="validationErrors(field)"
                  @input="touch(field)"
                  @blur="touch(field)"
                >
                  <template v-if="field.path === 'countries'" v-slot:item="{ item }">
                    <div class="d-flex">
                      <flag style="font-size: larger" :iso="item.id" class="mr-2" />{{ item.name }}
                    </div>
                  </template>
                  <template v-if="field.path === 'countries'" v-slot:selection="{ item }">
                    <div class="d-flex">
                      <flag style="font-size: larger" :iso="item.id" class="mr-2" />{{ item.name }}
                    </div>
                  </template>
                  <template v-if="field.path === 'availableSurveyClasses'" v-slot:item="{ item }">
                    {{ $t("survey.classes." + item) }}
                  </template>
                  <template v-if="field.path === 'availableSurveyClasses'" v-slot:selection="{ item }">
                    {{ $t("survey.classes." + item) }}
                  </template>
                </v-autocomplete>
              </template>
            </ApolloQuery>

            <date-text-field
              v-else-if="field.type == 'date'"
              v-model="modelEdit[field.name]"
              :label="field.label || $t(`${model}.${field.name}`)"
              :error-messages="validationErrors(field)"
              :bind="field.bind"
              @input="touch(field)"
              @blur="touch(field)"
            />

            <v-autocomplete
              v-else-if="field.type == 'autocomplete'"
              :label="field.label || $t(`${model}.${field.name}`)"
              :items="field.items"
              v-model="modelEdit[field.name]"
              v-bind="field.bind"
              auto-select-first
              :no-data-text="$t('no_results')"
              :error-messages="validationErrors(field)"
              @input="touch(field)"
              @blur="touch(field)"
            />

            <v-autocomplete
              v-else-if="field.type == 'morph-type'"
              :label="field.label || $t(`${model}.${field.name}`)"
              :items="field.items"
              v-model="modelEdit[field.name]"
              v-bind="field.bind"
              auto-select-first
              :no-data-text="$t('no_results')"
              :error-messages="validationErrors(field)"
              @input="touch(field)"
              @blur="touch(field)"
              @change="morphTypeChanged(field)"
            />

            <v-autocomplete
              v-else-if="field.type == 'time'"
              :label="field.label || $t(`${model}.${field.name}`)"
              :items="timeSlots"
              v-model="modelEdit[field.name]"
              v-bind="field.bind"
              auto-select-first
              :error-messages="validationErrors(field)"
              :no-data-text="$t('no_results')"
              @input="touch(field)"
              @blur="touch(field)"
            />

            <v-text-field
              v-else-if="field.type == 'number'"
              :label="field.label || $t(`${model}.${field.name}`)"
              v-bind="field.bind"
              type="number"
              v-model.number="modelEdit[field.name]"
              autocomplete="new-password"
              :error-messages="validationErrors(field)"
              @input="touch(field)"
              @blur="touch(field)"
            />

            <v-rating
              v-else-if="field.type == 'rating'"
              :label="field.label || $t(`${model}.${field.name}`)"
              v-bind="field.bind"
              v-model.number="modelEdit[field.name]"
              :error-messages="validationErrors(field)"
              @input="touch(field)"
              @blur="touch(field)"
            />

            <v-menu
              :close-on-content-click="false"
              v-else-if="field.type == 'color'"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-on="on"
                  :label="field.label || $t(`${model}.${field.name}`)"
                  v-bind="{ ...attrs, ...field.bind }"
                  v-model="modelEdit[field.name]"
                  autocomplete="new-password"
                  data-lpignore="true"
                  :error-messages="validationErrors(field)"
                  @input="touch(field)"
                  @blur="touch(field)"
                ></v-text-field>
              </template>
              <v-color-picker
                hide-canvas
                hide-inputs
                :swatches="swatches"
                show-swatches
                :value="modelEdit[field.name]"
                @update:color="(color) => (modelEdit[field.name] = color.hex)"
                :label="field.label || $t(`${model}.${field.name}`)"
                autocomplete="new-password"
              />
            </v-menu>

            <v-textarea
              v-else-if="field.type == 'textarea'"
              :label="field.label || $t(`${model}.${field.name}`)"
              v-bind="field.bind"
              v-model="modelEdit[field.name]"
              autocomplete="new-password"
              :error-messages="validationErrors(field)"
              @input="touch(field)"
              @blur="touch(field)"
            />

            <v-file-input
              v-else-if="field.type === 'file'"
              v-model="modelEdit[field.name]"
              :label="field.label || $t(`${model}.${field.name}`)"
              :rules="[checkFileSize]"
              clearable
              accept="image/jpeg,image/png,image/bmp,application/pdf"
            />

            <datetime-picker
              v-model="modelEdit[field.name]"
              :label="field.label || $t(`${model}.${field.name}`)"
              v-else-if="field.type === 'datetime'"
              :textFieldProps="{
                'prepend-icon': 'mdi-calendar',
                ...field.bind,
                'error-messages': validationErrors(field),
              }"
              @input="touch(field)"
              @blur="touch(field)"
            />

            <v-checkbox
              v-else-if="field.type === 'checkbox'"
              :label="field.label || $t(`${model}.${field.name}`)"
              v-bind="field.bind"
              v-model="modelEdit[field.name]"
              :error-messages="validationErrors(field)"
              @input="touch(field)"
              @blur="touch(field)"
            />

            <phone-number-input
              v-else-if="field.type === 'phone'"
              :label="field.label || $t(`${model}.${field.name}`)"
              v-bind="field.bind"
              v-model="modelEdit[field.name]"
              :error-messages="validationErrors(field)"
              @input="touch(field)"
              @blur="touch(field)"
            />

            <v-text-field
              v-else
              :label="field.label || $t(`${model}.${field.name}`)"
              v-bind="field.bind"
              v-model="modelEdit[field.name]"
              autocomplete="new-password"
              :error-messages="validationErrors(field)"
              @input="touch(field)"
              @blur="touch(field)"
            />
          </v-col>
        </v-row>
        <v-progress-circular v-else indeterminate :size="50" />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :invalid="$v.modelEdit.$invalid" :saving="saving" :reset="resetInfo" />
        <v-btn color="secondary" :disabled="saving" text @click="$emit('cancel')">
          {{ $t("dialog.cancel") }}
        </v-btn>
        <v-btn @click="resetInfo" :disabled="saving" color="warning" text> {{ $t("dialog.reset") }} </v-btn>
        <v-btn :disabled="$v.modelEdit.$invalid" :loading="saving" type="submit" color="primary" text>
          {{ $t("dialog.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { camelToSnakeCase, extractIds } from "../../apollo/helpers";
import _get from "lodash/get";
import { validationMixin } from "vuelidate";
import DatetimePicker from "../DatetimePicker.vue";
import DateTextField from "./inputs/DateTextField.vue";
import { getTimeSlots } from "@/maia/utils";
import PhoneNumberInput from "./inputs/PhoneNumberInput.vue";

export default {
  components: { DatetimePicker, DateTextField, PhoneNumberInput },
  name: "BaseForm",

  mixins: [validationMixin],

  validations() {
    return {
      modelEdit: this.visibleFields
        .filter((field) => field.validation)
        .reduce((acc, field) => {
          acc[field.name] = field.validation;
          return acc;
        }, {}),
    };
  },

  props: {
    currentValue: {
      type: Object,
      default: () => ({}),
    },
    defaultValue: {
      type: Object,
      default: () => ({}),
    },
    graphQLErrors: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
    saving: Boolean,
    model: String,
    fields: Array,
    title: String,
    duplicating: Boolean,
  },

  data() {
    return {
      modelEdit: { ...this.defaultValue, ...this.currentValue },
      swatches: [["#d11141"], ["#00b159"], ["#00aedb"], ["#f37735"], ["#ffc425"]],
    };
  },

  mounted() {
    this.$emit("input", this.returnValue);
    this.$v.modelEdit.$touch();
  },

  watch: {
    returnValue() {
      if (this.duplicating) {
        // eslint-disable-next-line no-unused-vars
        const { id, __typename, ...duplicateValue } = this.returnValue;

        this.$emit("input", duplicateValue);
        return;
      }
      this.$emit("input", this.returnValue);
    },

    currentValue() {
      this.resetInfo();
    },

    duplicating() {
      this.resetInfo();
    },

    visibleFields() {
      this.$v.modelEdit.$touch();
    },
  },

  computed: {
    visibleFields() {
      return this.fields.filter((field) => !field.isVisible || field.isVisible(this.modelEdit));
    },

    returnValue() {
      let returnValue = this.checkQueryFields(this.modelEdit);
      returnValue = this.checkFileFields(returnValue);
      returnValue = this.checkColorFields(returnValue);
      returnValue = this.checkExcludeFields(returnValue);
      if (!this.duplicating) returnValue = this.filterUnchangedFields(returnValue);
      return returnValue;
    },

    timeSlots() {
      return getTimeSlots("8:00", "23:45");
    },

    genericGQLErrors() {
      return this.graphQLErrors?.filter((error) => error.extensions?.validation == null).map(({ message }) => message);
    },
  },

  methods: {
    validationErrors({ name, validation, label }) {
      const errors = this.gqlValidationErrors(name);
      if (!validation || !this.$v.modelEdit[name]?.$error) return errors;
      //Required
      if (this.$v.modelEdit[name].required === false)
        errors.push(this.$t("form.validation.field_required", { field: label || this.$t(`${this.model}.${name}`) }));
      //Email
      if (this.$v.modelEdit[name].email === false)
        errors.push(this.$t("form.validation.email", { field: label || this.$t(`${this.model}.${name}`) }));
      //Field length
      if (this.$v.modelEdit[name].minLength === false)
        errors.push(
          this.$t("form.validation.min_length", {
            field: this.$t("user.password"),
            length: this.$v.modelEdit[name].$params.minLength.min,
          })
        );
      //Password mismatch
      if (this.$v.modelEdit[name].sameAsPassword === false)
        errors.push(this.$t("form.validation.password_mismatch", { field: label || this.$t(`${this.model}.${name}`) }));
      //Numeric
      if (this.$v.modelEdit[name].numeric === false)
        errors.push(this.$t("form.validation.numeric", { field: label || this.$t(`${this.model}.${name}`) }));
      //Integer
      if (this.$v.modelEdit[name].integer === false)
        errors.push(this.$t("form.validation.integer", { field: label || this.$t(`${this.model}.${name}`) }));
      //URL
      if (this.$v.modelEdit[name].url === false)
        errors.push(this.$t("form.validation.url", { field: label || this.$t(`${this.model}.${name}`) }));
      //Min value
      if (this.$v.modelEdit[name].minValue === false)
        errors.push(
          this.$t("form.validation.min_value", {
            field: label || this.$t(`${this.model}.${name}`),
            value: this.$v.modelEdit[name].$params.minValue.min,
          })
        );
      //Max value
      if (this.$v.modelEdit[name].maxValue === false)
        errors.push(
          this.$t("form.validation.max_value", {
            field: label || this.$t(`${this.model}.${name}`),
            value: this.$v.modelEdit[name].$params.maxValue.max,
          })
        );
      //Later date
      if (this.$v.modelEdit[name].laterThan === false)
        errors.push(
          this.$t("form.validation.later_date", {
            field: label || this.$t(`${this.model}.${name}`),
            date: this.$t(`${this.model}.${this.$v.modelEdit[name].$params.laterThan.date}`),
          })
        );
      if (errors.length === 0) errors.push("Unknown error");
      return errors;
    },

    gqlValidationErrors(name) {
      const errors = [];
      this.graphQLErrors?.forEach((error) => {
        const message = this.getGraphQLError(error, name);
        if (message) errors.push(this.$t(`errors.${message}`));
      });
      return errors;
    },

    getGraphQLError(graphQLError, name) {
      let validationError = _get(graphQLError, `extensions.validation`);
      validationError = validationError && validationError[`input.${name}`];

      if (validationError) return validationError[0];

      return null;
    },

    _get,

    checkFileSize(file) {
      if (!file) return true;

      if (file.size > 8e6) return this.$t("file_rules.size", { size: 8 });
      else return true;
    },

    resetInfo() {
      this.modelEdit = JSON.parse(JSON.stringify({ ...this.defaultValue, ...this.currentValue }));
      if (this.duplicating) {
        const fileFields = this.fields.filter((field) => field.type == "file").map((field) => field.name);
        for (const FIELD of fileFields) delete this.modelEdit[FIELD];
      }
    },

    filterUnchangedFields(input) {
      const originalItem = this.checkQueryFields(this.currentValue);
      Object.keys(input).forEach((key) => {
        if (input[key] === originalItem[key]) delete input[key];
      });
      return input;
    },

    checkQueryFields(input) {
      const queryFields = this.fields.filter((field) => field.type == "query");

      input = extractIds(input);

      queryFields.forEach((field) => {
        if (field.bind?.multiple && input[field.name] != null) {
          const temp = input[field.name];
          delete input[field.name];
          input[field.name] = { sync: temp.map(({ id }) => id) };
        } else if (input[field.name] === null) {
          delete input[field.name];
          input[`${camelToSnakeCase(field.name)}_id`] = null;
        }
      });

      return input;
    },

    checkFileFields(input) {
      const fileFields = this.fields.filter((field) => field.type == "file").map((field) => field.name);
      if (fileFields.length == 0) return input;

      input.files = { create: [], delete: [] };

      fileFields.forEach((field) => {
        if (input[field]) {
          input.files.create.push({
            name: field,
            file: input[field],
          });
        } else if (this.currentValue[field] && this.currentValue[field].id != this.modelEdit[field]?.id) {
          input.files.delete.push(this.currentValue[field].id);
        }
        delete input[field];
      });
      return input;
    },

    checkColorFields(input) {
      const fileFields = this.fields.filter((field) => field.type == "color").map((field) => field.name);
      if (fileFields.length == 0) return input;

      fileFields.forEach((field) => {
        if (input[field] && typeof input[field] == "object") input[field] = input[field].hex;
      });
      return input;
    },

    checkExcludeFields(input) {
      const excludeFields = this.fields.filter((field) => field.exclude).map((field) => field.name);

      excludeFields.forEach((field) => {
        delete input[field];
      });

      return input;
    },

    touch({ name, validation }) {
      if (!validation) return;
      this.$v.modelEdit[name].$touch();
    },

    save() {
      this.$v.modelEdit.$touch();
      if (this.$v.modelEdit.$invalid) {
        return;
      }
      this.$emit("save");
    },

    morphTypeChanged(type) {
      const morph = type.name.replace("_type", "");
      delete this.modelEdit[morph];
    },
  },
};
</script>
