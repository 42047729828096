<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <AnnouncementsBoard></AnnouncementsBoard>
      </v-col>
      <v-col cols="12">
        <v-card
          class="overflow-hidden mb-4"
          rounded="lg"
          :class="{
            'd-none': areasLoading || viewableEducationAreas.length === 1,
          }"
        >
          <v-card-title>
            <v-select
              item-value="id"
              item-text="name"
              v-model="selectedArea"
              :items="viewableEducationAreas"
              :loading="areasLoading"
              :label="$t('area.name')"
              return-object
            />
          </v-card-title>
        </v-card>
        <calendar
          :loading="areasLoading || loading"
          :events="events"
          v-resize="onResize"
          v-model="range"
          :type="type"
        />
      </v-col>
      <v-col cols="12">
        <v-card rounded="lg">
          <v-card-title style="word-break: break-word">
            <span class="text-wrap text-uppercase text-h6 text-center text-md-left">{{ $t("latest_events") }}</span>
          </v-card-title>
          <v-card-text>
            <crud-table
              @page="page = $event"
              :table-props="tableProps"
              model="event"
              :loading="latestEventsLoading"
              :items="latestEvents.data"
              hideHeader
              viewOnly
            >
              <template v-slot:[`name`]="{ item }">
                <router-link :to="{ name: 'Event', params: { id: item.id } }">{{ item.name }} </router-link>
              </template></crud-table
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { HEADERS } from "@/maia/headers";

import moment from "moment";

import CrudTable from "@/components/base/CrudTable.vue";
import gql from "graphql-tag";
import Calendar, { CALENDAR_EVENT_FRAGMENT } from "../components/Calendar.vue";
import AnnouncementsBoard from "@/components/home/AnnouncementsBoard.vue";

const LATEST_EVENTS_QUERY = gql`
  query LatestEvents($area_id: ID!, $page: Int) {
    area(id: $area_id) {
      id
      latestEvents(page: $page) {
        data {
          id
          name
          start_timestamp
          end_timestamp
          course {
            id
            shared
          }
        }
        paginatorInfo {
          currentPage
          lastPage
          total
        }
      }
    }
  }
`;

const HOME_QUERY = gql`
  query HomeQuery($area_id: ID!, $range: DateRange!) {
    area(id: $area_id) {
      id
      events(range: $range) {
        id
        ...calendarEvent
      }
      sharedEvents(range: $range) {
        id
        ...calendarEvent
      }
    }
  }
  ${CALENDAR_EVENT_FRAGMENT}
`;

export default {
  name: "Home",

  components: {
    Calendar,
    CrudTable,
    AnnouncementsBoard,
  },

  computed: {
    events() {
      return this.area === null ? [] : [...this.area.events, ...this.area.sharedEvents];
    },
    loading() {
      return this.$apollo.queries.area.loading;
    },
    areasLoading() {
      return this.$apollo.queries.viewableEducationAreas.loading;
    },
    latestEventsLoading() {
      return this.$apollo.queries.latestEvents.loading;
    },

    tableProps() {
      return {
        "server-items-length": this.latestEvents.paginatorInfo.total,
        itemsPerPage: 10,
        footerProps: {
          showFirstLastPage: true,
          "disable-items-per-page": true,
        },
        "disable-sort": true,
      };
    },
  },

  apollo: {
    viewableEducationAreas: {
      query: gql`
        query HomeEducationAreas {
          viewableEducationAreas {
            id
            name
          }
        }
      `,
      update({ viewableEducationAreas }) {
        if (viewableEducationAreas.length > 0) this.selectedArea = viewableEducationAreas[0];

        return viewableEducationAreas;
      },
    },
    area: {
      query: HOME_QUERY,
      variables() {
        return {
          area_id: this.selectedArea.id,
          range: this.range,
        };
      },
      skip() {
        return this.selectedArea == null || this.range.from == null || this.range.to == null;
      },
    },
    latestEvents: {
      query: LATEST_EVENTS_QUERY,
      variables() {
        return {
          area_id: this.selectedArea.id,
          page: this.page,
        };
      },
      skip() {
        return this.selectedArea == null;
      },
      update: ({ area }) => area.latestEvents,
    },
  },

  data() {
    return {
      today: moment().format("YYYY-MM-DD"),
      headers: HEADERS.scoreboard.map((column) => ({ ...column, text: this.$t("headers." + column.text) })),
      expanded: ["name", "residence"],
      range: {
        from: null,
        to: null,
      },
      type: "week",
      page: 1,
      latestEvents: {
        data: [],
        paginatorInfo: { currentPage: 0, lastPage: 0, total: 0 },
      },
      selectedArea: null,
      area: null,
      viewableEducationAreas: [],
    };
  },
  methods: {
    onResize() {
      if (window.innerWidth > 960) {
        this.type = "week";
      } else {
        this.type = "day";
      }
    },
    formatScoreboard(scoreboard) {
      return scoreboard.map((row) => ({
        ...row,
        name: `${row.user.name} ${row.user.surname}`,
        residence: row.user.residence.name,
        time: moment.utc(row.time * 1000).format("HH[h] mm[m]"),
      }));
    },
  },
};
</script>
