<template>
  <v-card v-if="event" outlined min-width="250px" flat>
    <v-toolbar class="darken-1" :elevation="0" :color="event.course.tag.color" dark dense>
      <v-toolbar-title v-html="event.name" />
      <v-spacer></v-spacer>
      <v-btn :to="{ name: 'Event', params: { id: event.id } }" icon>
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <!-- <v-btn icon>
            <v-icon>mdi-heart</v-icon>
          </v-btn> -->
    </v-toolbar>
    <v-card-text>
      <model-info model="course" field="name" :value="event.course.name" />
      <model-info model="room" field="name" :value="event.room.name" />
      <model-info model="event" field="mode" :value="event.mode" type="autocomplete" />

      <v-btn color="info" v-if="event.poster" small target="_blank" @click="$emit('show-poster', event.poster)">
        {{ $t(`view`) }}
      </v-btn>
    </v-card-text>

    <!-- <div v-if="event.canUpdate"> -->
    <div v-if="false">
      <v-divider />
      <v-card-actions class="justify-end">
        <v-btn @click="dialog = true" color="primary">
          {{ $t("edit") }}
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="dialog" max-width="800px" persistent>
        <event-form @cancel="dialog = false" :event_id="event.id" />
      </v-dialog>
    </div>
    <!-- <v-card-actions class="justify-end">
      <v-btn :to="`/event/${event.id}`" text color="primary"> Vai </v-btn>
      <v-btn text color="secondary" @click="$emit('close')"> Chiudi </v-btn>
    </v-card-actions> -->
  </v-card>
  <v-sheet v-else>
    <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
  </v-sheet>
</template>

<script>
import gql from "graphql-tag";
import moment from "moment";
import ModelInfo from "../base/ModelInfo.vue";
import EventForm from "../forms/EventForm.vue";

export default {
  components: { ModelInfo, EventForm },
  props: { event_id: { required: true } },
  name: "EventWindow",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    moment: moment,
  },

  apollo: {
    event: {
      query: gql`
        query EventWindowInfo($id: ID!) {
          event(id: $id) {
            id
            name
            mode
            canUpdate
            poster: file(name: "poster") {
              id
              link
            }
            room {
              id
              name
            }
            course {
              id
              name
              tag {
                id
                color
              }
            }
          }
        }
      `,
      variables() {
        return { id: this.event_id };
      },
    },
  },

  watch: {
    dialog(value) {
      this.$emit("editing", value);
    },
  },
};
</script>

<style></style>
