<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col
              v-for="key in formFields"
              :key="key"
              style="max-width: 100%"
              class="flex-grow-1 flex-shrink-0"
              cols="12"
              :md="modelObject[key].type == 'textarea' ? '12' : '6'"
              :order="modelObject[key].type == 'textarea' ? 'last' : null">
              <ApolloQuery
                v-if="isForeignId(key)"
                :query="buildForeignIdQuery(key)"
                :variables="{
                  id: item[parent_id],
                }"
                :skip="modelObject[key].query_path && !item[parent_id]">
                <template v-slot="{ result: { data, loading } }">
                  <!-- Result -->
                  <v-autocomplete
                    :loading="loading"
                    :items="!loading && data ? _get(data, modelObject[key].query_path || getPlural(getField(key))) : []"
                    :item-text="getItemText(key)"
                    :clearable="modelObject[key].nullable"
                    item-value="id"
                    :multiple="modelObject[key].multiple"
                    :small-chips="modelObject[key].multiple"
                    :label="$tc('fields.' + (modelObject[key].label || getField(key)), modelObject[key].multiple ? 2 : 1)"
                    v-model="item[key]" /> </template
              ></ApolloQuery>

              <v-autocomplete
                v-else-if="modelObject[key].type == 'autocomplete'"
                :items="createOptions(key, modelObject[key].options)"
                :label="$tc('fields.' + key, 1)"
                v-model="item[key]" />

              <v-combobox
                v-else-if="modelObject[key].type == 'combobox'"
                :items="createOptions(key, modelObject[key].options)"
                :label="$tc('fields.' + key, 1)"
                v-model="item[key]"
                :return-object="false" />

              <v-checkbox v-else-if="isCheckbox(key)" :label="$tc('fields.' + key, 1)" v-model="item[key]" />

              <v-file-input
                v-else-if="modelObject[key].type === 'file'"
                v-model="item[key]"
                :label="$tc('fields.' + key, 1)"
                :rules="[checkFileSize]"
                :clearable="modelObject[key].nullable" />

              <v-text-field
                v-else-if="modelObject[key].type == 'number'"
                v-model.number="item[key]"
                :step="modelObject[key].step ? modelObject[key].step : 1"
                :type="modelObject[key].type"
                :label="$tc('fields.' + key, 1)"></v-text-field>

              <datetime-picker v-model="item[key]" :label="$tc('fields.' + key, 1)" v-else-if="modelObject[key].type == 'datetime'" />

              <date-text-field
                v-else-if="modelObject[key].type == 'date'"
                v-model="item[key]"
                :label="$tc('fields.' + key, 1)"
                :clearable="modelObject[key].nullable" />

              <v-menu
                :close-on-content-click="false"
                v-else-if="modelObject[key].type == 'color'"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :label="$tc('fields.' + key, 1)" v-bind="attrs" v-on="on" v-model="item[key]"></v-text-field>
                </template>
                <v-color-picker hide-canvas hide-inputs :swatches="swatches" show-swatches v-model="item[key]" :label="$tc('fields.' + key, 1)" />
              </v-menu>

              <v-textarea
                v-else-if="modelObject[key].type == 'textarea'"
                v-model="item[key]"
                :type="modelObject[key].type"
                :label="$tc('fields.' + key, 1)"></v-textarea>

              <v-text-field
                v-else-if="modelObject[key].type != null"
                v-model="item[key]"
                :type="modelObject[key].type"
                :label="$tc('fields.' + key, 1)"></v-text-field>

              <v-text-field v-else v-model="item[key]" :label="$tc('fields.' + key, 1)" :type="modelObject[key].type || 'text'"></v-text-field>
            </v-col>
          </v-row> </v-container
      ></v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="() => this.$emit('close')">
        {{ $t("dialog.cancel") }}
      </v-btn>
      <v-btn color="blue darken-1" text @click="() => this.$emit('save', { ...this.item })">
        {{ primaryActionText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { MODELS } from "@/maia/models";

import _get from "lodash/get";
import moment from "moment";
import DatetimePicker from "./DatetimePicker.vue";
import DateTextField from "./forms/inputs/DateTextField.vue";

export default {
  components: { DatetimePicker, DateTextField },
  name: "CreateEditDialog",

  props: {
    model: String,
    editedItem: {
      type: Object,
      default: () => ({}),
    },
    baseItem: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      item: { ...this.defaultItem, ...this.baseItem },
      moment,

      swatches: [["#d11141"], ["#00b159"], ["#00aedb"], ["#f37735"], ["#ffc425"]],
    };
  },

  computed: {
    formTitle() {
      return (this.isNew ? this.$t("dialog.new") : this.$t("dialog.edit")) + " " + this.$tc("models." + this.model, 1);
    },
    primaryActionText() {
      return this.isNew ? this.$t("dialog.add") : this.$t("dialog.save");
    },
    formFields() {
      return Object.keys(this.modelObject).filter((key) => !this.modelObject[key].hidden);
    },
    modelObject() {
      return MODELS[`${this.model.toUpperCase()}MODEL`];
    },
    defaultItem() {
      let defaults = {};

      Object.keys(this.modelObject).forEach((key) => {
        defaults[key] = this.modelObject[key].default;
      });

      return { ...defaults, ...this.baseItem };
    },
    parent_id() {
      return Object.keys(this.modelObject).find((key) => this.modelObject[key].is_parent_id);
    },
    isNew() {
      return Object.keys(this.editedItem).length === 0;
    },
  },

  created() {
    this.mapKeys(this.editedItem);
  },

  watch: {
    model() {
      this.item = { ...this.defaultItem, ...this.baseItem };
    },
    editedItem(item) {
      this.mapKeys(item);
    },
    baseItem(baseItem) {
      this.item = { ...this.defaultItem, ...baseItem };
    },
  },

  methods: {
    _get,

    datetimeChange(value, key) {
      if (value) this.item[key] = moment(value).format("YYYY-MM-DD HH:mm:ss");
      else this.item[key] = null;
    },

    mapKeys(item) {
      if (Object.keys(item).length !== 0)
        Object.keys(item)
          .filter((key) => key in this.defaultItem)
          .forEach((key) => (this.item[key] = item[key]));
      else {
        this.item = Object.assign({}, this.defaultItem);
      }
    },

    checkFileSize(file) {
      if (!file) return true;

      if (file.size > 8e6) return "File size should be less than 8 MB";
      else return true;
    },

    isForeignId(key) {
      return key.endsWith("_id");
    },
    isSelect(key) {
      return key.endsWith("_select");
    },
    isCheckbox(key) {
      return this.modelObject[key].type == "checkbox";
    },

    getField(key) {
      return this.snakeToCamel(key.replace("_id", "").replace("_select", "").replace("_checkbox", ""));
    },

    buildForeignIdQuery(key) {
      let field = this.modelObject[key];

      let name;

      if (field.query_path) field.parent_id_variable ??= "id";

      if (field.text) {
        name = field.text.replaceAll(".", "{id,") + "}".repeat(field.text.split(".").length - 1);
      } else name = "name";

      let query_nodes = field.query_path ? field.query_path.split(".") : [this.getPlural(this.getField(key))];

      const queryBuilder = (name) => {
        let query_string = `query ${query_nodes[0]}`;

        if (field.parent_id_variable) query_string += `($${field.parent_id_variable}: ID!)`;

        query_string += `{${query_nodes[0]}`;

        if (field.parent_id_variable) query_string += `(${field.parent_id_variable}: $${field.parent_id_variable})`;

        query_string += `{id, `;

        query_nodes.slice(1).forEach((node) => {
          query_string += `${node}{ id, `;
        });

        query_string += name;

        query_string += `}`.repeat(query_nodes.length + 1);

        return gql`
          ${query_string}
        `;
      };

      return queryBuilder(name);
    },

    getItemText(key) {
      return this.modelObject[key].text || "name";
    },

    getPlural(field) {
      if (field.slice(-1) == "s") {
        field += "e";
      }
      return field + "s";
    },

    createOptions(key, options) {
      return options.map((item) => {
        if (typeof item == "object")
          return {
            value: item.key,
            text: this.modelObject[key].no_translation ? item.text : this.$t(`options.${this.model}.${key}.${item.text}`),
          };
        else
          return {
            value: item,
            text: this.modelObject[key].no_translation ? item : this.$t(`options.${this.model}.${key}.${item}`),
          };
      });
    },

    snakeToCamel(str) {
      return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));
    },
  },
};
</script>
