<template>
  <v-list-item>
    <v-list-item-content class="py-2">
      <v-list-item-subtitle class="text-subtitle-1">
        {{ title }}
      </v-list-item-subtitle>
      <v-list-item-title class="field">
        <span v-if="value == null || value === ''">({{ $t("empty") }})</span>
        <v-btn
          v-else-if="type === 'link'"
          target="_blank"
          :href="value"
          class="px-0"
          text
          color="primary"
          link
          >{{ $te(`${model}.links.${field}`) ? $t(`${model}.links.${field}`) : $t(`open_link`) }}</v-btn
        >
        <v-btn v-else-if="type === 'file'" color="info" small target="_blank" @click="preview = true">
          {{ $t(`view`) }}
          <file-preview v-model="preview" :src="value.link" :file_name="actualFileName" :title="title" />
        </v-btn>
        <span v-else-if="type === 'textarea'" style="word-break: break-word" class="text-wrap">{{
          value
        }}</span>
        <span v-else-if="type === 'date'">{{ moment(value).format("LL") }}</span>
        <span v-else-if="type === 'datetime'">{{ moment(value).format("lll") }}</span>
        <span v-else-if="type === 'nationality'">
          <span class="mr-1">{{ value }} </span><flag :iso="value" />
        </span>
        <v-rating readonly half-increments :value="value" v-else-if="type === 'rating'"></v-rating>
        <span v-else-if="type === 'currency' || type === 'float'">{{
          value.toLocaleString($i18n.locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        }}</span>
        <span v-else-if="type === 'autocomplete'">
          {{ $t(`options.${model}.${field}.${value}`) }}
        </span>
        <span class="text-wrap" v-else>{{ value }}</span>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import moment from "moment";
import FilePreview from "./FilePreview.vue";
export default {
  components: { FilePreview },
  name: "ModelInfo",
  props: {
    type: {
      type: String,
    },
    field: {
      type: String,
    },
    model: {
      type: String,
    },
    label: {
      type: String,
    },
    filename: {
      type: String,
    },
    value: {},
  },
  data() {
    return {
      moment,
      preview: false,
    };
  },

  computed: {
    actualFileName() {
      if (this.filename) return this.filename;

      return this.label || this.$t(`${this.model}.${this.field}`);
    },

    title() {
      return this.label || this.$t(`${this.model}.${this.field}`);
    },
  },
};
</script>
