<template>
  <v-input :error-messages="errorMessages">
    <v-row no-gutters class="flex-nowrap">
      <v-col class="flex-shrink-1">
        <v-autocomplete
          item-text="callingCode"
          item-value="callingCode"
          v-model="callingCode"
          :items="callingCodes"
          :label="$t('fields.prefix')"
          hide-details
          @change="emitChange"
          :loading="$apollo.queries.callingCodes.loading"
          :filter="filter"
          :error="errorMessages.length > 0"
          @blur="$emit('blur')"
        >
          <template v-slot:item="{ item }">
            <div class="d-flex flex-nowrap">
              <flag style="font-size: larger" :iso="item.id" class="mr-2" />{{ item.callingCode }} ({{
                item.name
              }})
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <div class="d-flex flex-nowrap">
              <flag style="font-size: larger" :iso="item.id" class="mr-2" />{{ item.callingCode }}
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="auto" class="flex-grow-1">
        <v-text-field
          pattern="[0-9]{10}"
          v-model="phoneNumberValue"
          :label="label"
          @input="emitChange"
          hide-details
          :error="errorMessages.length > 0"
          @blur="$emit('blur')"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-input>
</template>

<script>
import gql from "graphql-tag";

export default {
  props: {
    value: String, // the v-model value for the phone number
    label: String,
    errorMessages: Array,
  },
  data() {
    return {
      callingCode: "",
      phoneNumberValue: "",
      callingCodes: [],
    };
  },
  methods: {
    filter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.callingCode.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    extractPhoneNumber(value) {
      const matchingPrefix = this.callingCodes.find(({ callingCode }) =>
        value.startsWith("00" + callingCode.substring(1))
      );
      if (matchingPrefix) {
        this.callingCode = matchingPrefix.callingCode;
        this.phoneNumberValue = value.substring(this.callingCode.length + 1);
      } else {
        this.callingCode = "";
        this.phoneNumberValue = value;
      }
    },
    emitChange() {
      if (!this.callingCode) {
        this.$emit("input", this.phoneNumberValue);
        return;
      }

      let formattedCallingCode = "00".concat(this.callingCode.substring(1));
      this.$emit("input", formattedCallingCode + this.phoneNumberValue);
    },
  },
  apollo: {
    callingCodes: {
      query: gql`
        query {
          countries {
            id
            name
            callingCode
          }
        }
      `,
      update(data) {
        return data.countries;
      },
    },
  },
  watch: {
    value(newValue) {
      this.extractPhoneNumber(newValue);
    },
    callingCodes() {
      if (this.value) this.extractPhoneNumber(this.value);
    },
  },
};
</script>
