<template>
  <v-dialog :value="value" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card class="secondary">
      <v-toolbar rounded="0" dark color="primary">
        <v-btn icon dark @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn v-if="downloadable" :loading="downloading" dark text @click="download" icon> <v-icon>mdi-download</v-icon> </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-row no-gutters justify="center" v-if="isPdf">
        <v-col cols="12" md="10" xl="6" class="pa-2">
          <vue-pdf @num-pages="numPages = $event" :page="1" ref="transcript" :src="src" />
        </v-col>
        <v-col v-for="i in _range(2, numPages + 1)" :key="i" cols="12" md="10" xl="6" class="pa-2">
          <vue-pdf :page="i" ref="transcript" :src="src" />
        </v-col>
      </v-row>
      <div class="image-div" v-else>
        <v-img contain class="h-100" :src="src"></v-img>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import download from "../../maia/utils/download";
import VuePdf from "vue-pdf";
import _range from "lodash/range";

export default {
  name: "FilePreview",
  components: { VuePdf },
  props: {
    value: Boolean,
    title: String,
    file_name: { type: String, default: "download" },
    downloadable: { type: Boolean, default: true },
    src: String,
    pdf: Boolean,
  },
  data() {
    return {
      numPages: 10,
      downloading: false,
    };
  },
  computed: {
    isPdf() {
      return this.pdf || this.src.endsWith(".pdf");
    },
  },
  methods: {
    download() {
      if (this.isPdf) this.downloadPdf();
      else this.downloadImage();
    },
    downloadPdf() {
      this.downloading = true;
      download(this.src, this.file_name).then(() => {
        this.$emit("input", false);
        this.downloading = false;
      });
    },
    downloadImage() {
      this.downloading = true;
      const extension = this.src.split(".")[1];
      const name = `${this.file_name}.${extension}`;

      fetch(this.src)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => alert("An error sorry"))
        .finally(() => (this.downloading = false));
    },
    setPageNumber(number) {
      console.log(number);
    },
    _range,
  },
  mounted() {
    if (this.isPdf) {
      const loadingTask = VuePdf.createLoadingTask(this.src);
      loadingTask.prom;
    }
  },
};
</script>

<style scoped lang="scss">
.image-div {
  height: calc(100vh - 64px);
}
</style>
