<template>
  <v-dialog v-model="display" :width="dialogWidth">
    <template v-slot:activator="{ on }">
      <v-text-field
        ref="textfield"
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :value="formattedDatetime"
        v-on="on"
        readonly
        @click:clear="clear"
      >
        <template v-slot:label>
          <slot name="label">{{ label }}</slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-tabs fixed-tabs v-model="activeTab">
        <v-tab key="calendar">
          <slot name="dateIcon">
            <v-icon>mdi-calendar</v-icon>
          </slot>
        </v-tab>
        <v-tab key="timer" :disabled="dateNotSelected">
          <slot name="timeIcon">
            <v-icon>mdi-clock</v-icon>
          </slot>
        </v-tab>
        <v-tab-item class="my-1" key="calendar">
          <v-date-picker
            v-model="date"
            v-bind="datePickerProps"
            @input="showTimePicker"
            full-width
            :locale="$i18n.locale"
            first-day-of-week="1"
          ></v-date-picker>
        </v-tab-item>
        <v-tab-item class="my-1" key="timer">
          <v-divider v-if="digital" />
          <v-expand-transition>
            <v-container v-if="digital">
              <v-row>
                <v-col
                  ><v-autocomplete
                    :label="$t('dialog.hour')"
                    v-model="selectedHour"
                    auto-select-first
                    :items="hours"
                /></v-col>
                <v-col
                  ><v-autocomplete
                    :label="$t('dialog.minutes')"
                    v-model="selectedMinutes"
                    auto-select-first
                    :items="minutes"
                /></v-col>
              </v-row>
            </v-container>
            <v-time-picker
              :allowed-minutes="(m) => m % 15 === 0"
              :allowed-hours="_range(8, 24)"
              ref="timer"
              v-else
              v-model="time"
              v-bind="timePickerProps"
              full-width
              format="24hr"
            ></v-time-picker>
          </v-expand-transition>
        </v-tab-item>
      </v-tabs>
      <v-divider />
      <v-card-actions>
        <v-btn-toggle dense v-if="activeTab == 1" v-model="digital">
          <v-btn small fab icon><v-icon>mdi-clock</v-icon></v-btn>
          <v-btn small fab icon><v-icon>mdi-clock-digital</v-icon></v-btn>
        </v-btn-toggle>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn color="secondary" text @click.native="clearHandler">{{ $t("dialog.cancel") }}</v-btn>
          <v-btn color="primary" text @click="okHandler">{{ $t("dialog.ok") }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const DEFAULT_DATE = "";
const DEFAULT_TIME = "";
const DEFAULT_DIALOG_WIDTH = 400;

import moment from "moment";

import _range from "lodash/range";

import { getTimeSlots } from "@/maia/utils";

export default {
  name: "v-datetime-picker",

  model: {
    prop: "datetime",
    event: "input",
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    textFieldProps: {
      type: Object,
    },
    datePickerProps: {
      type: Object,
    },
    timePickerProps: {
      type: Object,
    },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
      digital: 0,
      timeSlots: getTimeSlots("8:00", "23:45"),
      hours: _range(8, 24).map((m) => m.toString().padStart(2, "0")),
      minutes: _range(0, 60, 15).map((m) => m.toString().padStart(2, "0")),
      selectedHour: null,
      selectedMinutes: null,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    formattedDatetime() {
      return this.selectedDatetime ? moment(this.selectedDatetime).format("lll") : "";
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + " " + this.time;
        if (this.time.length === 5) {
          datetimeString += ":00";
        }

        return moment(datetimeString).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return null;
      }
    },
    dateNotSelected() {
      return !this.date;
    },
  },
  methods: {
    _range,

    init() {
      if (this.datetime) {
        this.date = moment(this.datetime).format("YYYY-MM-DD");
        this.time = moment(this.datetime).format("HH:mm");
      }
      this.$emit("input", this.selectedDatetime);
    },
    okHandler() {
      this.resetPicker();
      this.$emit("input", this.selectedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
    },
    resetPicker() {
      this.display = false;
      this.$refs.textfield.blur();
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    },

    clear() {
      this.date = null;
      this.time = null;
      this.$refs.textfield.blur();
      this.$emit("blur");
    },
  },
  watch: {
    datetime() {
      this.init();
    },
    display(value) {
      if (!value) this.$emit("blur");
    },
    selectedDatetime() {
      this.$emit("input");
    },
    selectedHour() {
      if (!this.selectedHour || !this.selectedMinutes) return null;

      this.time = `${this.selectedHour}:${this.selectedMinutes}`;
    },
    selectedMinutes() {
      if (!this.selectedHour || !this.selectedMinutes) return null;

      this.time = `${this.selectedHour}:${this.selectedMinutes}`;
    },
    time() {
      if (this.time == null) {
        this.selectedHour = null;
        this.selectedMinutes = null;
        return;
      }

      const [h, m] = this.time.split(":");
      this.selectedHour = h;
      this.selectedMinutes = m;
    },
  },
};
</script>
