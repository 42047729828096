<template>
  <div>
    <Announcement v-for="announcement in currentAnnouncements" :key="announcement.id" :announcement="announcement" />
  </div>
</template>

<script>
import Announcement, { ANNOUNCEMENT_DATA_FRAGMENT } from "./Announcement.vue";
import gql from "graphql-tag";

export default {
  components: { Announcement },
  data() {
    return {
      currentAnnouncements: [],
    };
  },
  apollo: {
    currentAnnouncements: {
      query: gql`
        query currentAnnouncements {
          currentAnnouncements {
            id
            ...announcementData
          }
        }
        ${ANNOUNCEMENT_DATA_FRAGMENT}
      `,
      fetchPolicy: "cache-and-network",
    },
  },
};
</script>
