<template>
  <base-form
    model="event"
    @save="event_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.event.loading"
    :currentValue="event"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";
import laterThan from "../../validators/laterThan";
import _sortBy from "lodash/sortBy";
import { getEnrollableFields, ENROLLABLE_FRAGMENT } from "./enrollable";
import { getPayableFields, PAYABLE_FRAGMENT } from "./payable";
import { minValue } from "vuelidate/lib/validators";
import { maxValue } from "vuelidate/lib/validators";

export const EVENT_FRAGMENT = gql`
  fragment event on Event {
    id
    name
    start_timestamp
    end_timestamp
    mode
    acknowledged
    acknowledgment_ratio
    argument
    teacher
    organizer
    link
    type
    qualification
    cost
    context
    notes
    ...enrollableForm
    ...payableForm
    poster: file(name: "poster") {
      id
      name
      link
    }
    report: file(name: "report") {
      id
      name
      link
    }
    course {
      id
      name
      reports_enabled
      sharedWith {
        id
      }
      area {
        id
      }
      year {
        id
      }
    }
    delegate {
      id
      complete_name
      residence {
        id
        area {
          id
          abbreviation
        }
      }
    }
    room {
      id
      name
    }
  }
  ${ENROLLABLE_FRAGMENT}
  ${PAYABLE_FRAGMENT}
`;

export default {
  components: { BaseForm },
  name: "EventForm",
  props: {
    event_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  apollo: {
    event: {
      query: gql`
        query Event($id: ID!) {
          event(id: $id) {
            ...event
          }
        }
        ${EVENT_FRAGMENT}
      `,
      variables() {
        return { id: this.event_id };
      },
      skip() {
        return this.event_id == null;
      },
    },
  },

  computed: {
    area_ids() {
      if (this.event_id) {
        return [this.event.course.area.id, ...this.event.course.sharedWith.map((area) => area.id)];
      }

      let ids;

      if (this.baseItem.course.sharedWith?.length)
        ids = [this.baseItem.course.area.id, ...this.baseItem.course.sharedWith.map((area) => area.id)];
      else ids = [this.baseItem.course.area.id];

      return ids;
    },
  },

  watch: {
    event_id(val) {
      if (val == null) this.event = {};
    },
  },

  data() {
    return {
      newValue: {},
      event: {},
      saving: false,
      fields: [
        {
          name: "course",
          type: "query",
          path: "area.courses",
          query: gql`
            query Area($area_id: ID!, $year_id: ID) {
              area(id: $area_id) {
                id
                courses(year_id: $year_id) {
                  id
                  name
                }
              }
            }
          `,
          variables: () => {
            return {
              area_id: this.event_id ? this.event.course.area.id : this.baseItem.course.area.id,
              year_id: this.event_id ? this.event.course.year.id : this.baseItem.course.year.id,
            };
          },
          skip: () => this.event_id && this.$apollo.queries.event.loading,
          label: this.$t("course.name"),
        },
        {
          name: "name",
          validation: {
            required,
          },
        },
        {
          name: "start_timestamp",
          type: "datetime",
          validation: {
            required,
          },
        },
        {
          name: "end_timestamp",
          type: "datetime",
          validation: {
            required,
            laterThan: laterThan("start_timestamp"),
          },
        },
        {
          name: "acknowledged",
          type: "checkbox",
        },
        {
          name: "acknowledgment_ratio",
          type: "number",
          isVisible: ({ acknowledged }) => acknowledged,
          bind: {
            min: 0,
            max: 1,
            step: 0.01,
            clearable: true,
          },
          validation: {
            minValue: minValue(0),
            maxValue: maxValue(1),
          },
        },
        {
          name: "argument",
          validation: {
            required,
          },
          isVisible: ({ acknowledged }) => acknowledged,
          cols: 12,
        },
        { name: "organizer" },
        {
          name: "delegate",
          type: "query",
          path: "managers",
          query: gql`
            query AreasManagers($area_ids: [ID!]!) {
              areas(ids: $area_ids) {
                id
                managers {
                  id
                  name
                  surname
                  residence {
                    id
                    area {
                      id
                      abbreviation
                    }
                  }
                }
              }
            }
          `,
          variables: () => ({ area_ids: this.area_ids }),
          update: (data) => ({
            managers: _sortBy(
              data.areas.reduce((managers, area) => [...managers, ...area.managers], []),
              "complete_name"
            ),
          }),
          skip: () => this.event_id && this.$apollo.queries.event.loading,
          label: this.$t("event.delegate"),
          text: ({ name, surname, residence }) => `${surname} ${name} (${residence.area.abbreviation})`,
          bind: {
            clearable: true,
          },
        },
        {
          name: "teacher",
          validation: {
            required,
          },
          isVisible: ({ acknowledged }) => acknowledged,
        },
        {
          name: "qualification",
          type: "autocomplete",
          items: [0, 1].map((value) => ({
            text: this.$t(`options.event.qualification.${value}`),
            value,
          })),
          validation: {
            required,
          },
          isVisible: ({ acknowledged }) => acknowledged,
        },
        {
          name: "type",
          type: "autocomplete",
          items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value) => ({
            text: this.$t(`options.event.type.${value}`),
            value,
          })),
          validation: {
            required,
          },
          isVisible: ({ acknowledged }) => acknowledged,
        },
        {
          name: "mode",
          type: "autocomplete",
          items: ["live", "remote", "dual"].map((value) => ({
            text: this.$t(`options.event.mode.${value}`),
            value,
          })),
          validation: {
            required,
          },
        },
        {
          name: "room",
          type: "query",
          path: "rooms",
          query: gql`
            query AreasRooms($area_ids: [ID!]!) {
              areas(ids: $area_ids) {
                rooms {
                  id
                  name
                }
              }
            }
          `,
          variables: () => ({ area_ids: this.area_ids }),
          update: (data) => ({
            rooms: _sortBy(
              data.areas.reduce((rooms, area) => [...rooms, ...area.rooms], []),
              "name"
            ),
          }),
          skip: () => this.event_id && this.$apollo.queries.event.loading,
          label: this.$t("event.room"),
          bind: {
            clearable: true,
          },
          validation: {
            required,
          },
        },
        { name: "link" },
        {
          name: "context",
          type: "autocomplete",
          items: ["N", "I"].map((value) => ({
            text: this.$t(`options.event.context.${value}`),
            value,
          })),
          validation: {
            required,
          },
          isVisible: ({ acknowledged }) => acknowledged,
        },
        { name: "cost", default: 0, type: "number", step: 0.01 },
        ...this.getEnrollableFields(),
        ...this.getPayableFields(),
        {
          name: "poster",
          type: "file",
          cols: 12,
        },
        {
          name: "report",
          type: "file",
          cols: 12,
          isVisible: () =>
            this.event_id ? this.event.course?.reports_enabled : this.baseItem.course?.reports_enabled,
        },
        {
          name: "notes",
          type: "textarea",
          last: true,
          cols: 12,
        },
      ],
    };
  },
  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "Event",
            fragment: EVENT_FRAGMENT,
            fragment_name: "event",
          }),
          variables: {
            id: this.event_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      // eslint-disable-next-line no-unused-vars
      const { enrollments_status, ...input } = this.newValue;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Event",
            fragment: EVENT_FRAGMENT,
            fragment_name: "event",
          }),
          variables: {
            input,
          },
        })
        .then(({ data: { createEvent } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createEvent);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    getEnrollableFields,
    getPayableFields,
  },
};
</script>
