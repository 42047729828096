import { required } from "vuelidate/lib/validators";

export default function getEnrollableFields() {
  return [
    {
      name: "enrollments_enabled",
      label: this.$t("enrollable.enrollments_enabled"),
      type: "checkbox",
    },
    {
      name: "enrollments_max_number",
      label: this.$t("enrollable.enrollments_max_number"),
      type: "number",
      isVisible: ({ enrollments_enabled }) => enrollments_enabled,
      bind: {
        clearable: true,
      },
    },
    {
      name: "enrollments_start",
      label: this.$t("enrollable.enrollments_start"),
      type: "datetime",
      isVisible: ({ enrollments_enabled }) => enrollments_enabled,
      bind: {
        clearable: true,
      },
      validation: {
        required,
      },
    },
    {
      name: "enrollments_deadline",
      label: this.$t("enrollable.enrollments_deadline"),
      type: "datetime",
      isVisible: ({ enrollments_enabled }) => enrollments_enabled,
      bind: {
        clearable: true,
      },
      validation: {
        required,
      },
    },
  ];
}
